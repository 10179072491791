*,
*::before,
*::after {
  padding: 0px;
  margin: 0px;
  outline: none;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
}

button {
  display: inline-block;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: center;
  cursor: pointer;
}

input {
  all: unset;
}

textarea {
  all: unset;
}
