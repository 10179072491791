@font-face {
  font-family: 'DMSans-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/DMSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'DMSans-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/DMSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
}
