html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #fff;
  line-height: 1.6;
  color: #23374a;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'DMSans-Regular';
}

.slick-slide div {
  display: flex;
}

.input-field::placeholder {
  color: #9a9a9a; /* Замените на нужный вам цвет */
}

:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
}

.full-height {
  height: calc(
    100vh - var(--safe-area-inset-top) - var(--safe-area-inset-bottom)
  );
  padding-top: var(--safe-area-inset-top);
  padding-bottom: var(--safe-area-inset-bottom);
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

///
///
.swiper {
  padding: 0 20px !important;
}
